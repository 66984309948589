<template>
  <Card dense class="ui-side-content">
    <slot />
  </Card>
</template>
<script>
import { defineComponent } from 'vue';
import Card from '@/components/ui/Card.vue';

export default defineComponent({
  name: 'SideContent',
  components: { Card }
});
</script>
<style lang="scss">
@import '@/styles/spacing';
@import '@/styles/layout';

.ui-side-content {
  position: sticky;
  top: $header-navigation-height + $spacing-base * 6;
  height: calc(
    100vh - #{$header-navigation-height} - #{$spacing-base * 12} - #{$footer-navigation-height}
  );

  .card-content {
    height: 100%;
  }
}
</style>

<template>
  <div>
    <Flex align-center justify-center>
      <VueCropper
        ref="cropper"
        :src="imageSrc"
        :ready="ready"
        :alt="alt"
        :aspect-ratio="round ? 1 : 1 / 1"
        :view-mode="round ? 1 : undefined"
        :minContainerWidth="500"
        :class="{ round, loading: !croppable || loading }"
        class="cropper-content"
      />
      <Box v-if="!croppable || loading" position="absolute">
        <Loading />
      </Box>
    </Flex>
    <Flex justify-center class="mt-6">
      <IconButton
        icon-name="minus"
        size="m"
        @click="zoom(-0.1)"
        :disabled="!croppable || loading"
        class="mr-3"
      />
      <IconButton
        icon-name="plus"
        size="m"
        @click="zoom(0.1)"
        :disabled="!croppable || loading"
        class="ml-3"
      />
    </Flex>

    <Flex justify-center class="mt-6">
      <Button
        upload
        id="upload-image"
        variant="outlined"
        width="140px"
        size="m"
        class="mx-2"
        :disabled="!croppable"
        @change-file="onChangeFile"
      >
        ファイル選択
      </Button>
      <Button
        class="mx-2"
        width="140px"
        size="m"
        :disabled="!croppable"
        @click="crop"
      >
        決定
      </Button>
    </Flex>
  </div>
</template>
<script>
import { defineComponent, ref, onMounted } from 'vue';
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import Box from '@/components/layout/Box.vue';
import Flex from '@/components/layout/Flex.vue';
import Button from '@/components/ui/Button.vue';
import Loading from '@/components/ui/Loading.vue';
import IconButton from '@/components/ui/IconButton.vue';

export default defineComponent({
  name: 'UploadImage',
  components: {
    Box,
    Button,
    Flex,
    Loading,
    IconButton,
    VueCropper
  },
  props: {
    alt: String,
    src: String,
    round: Boolean,
    loading: Boolean
  },
  emits: ['cropped'],
  setup(props, { emit }) {
    const imageSrc = ref('');
    const cropper = ref(null);
    const croppable = ref(false);
    const file = ref();

    const getRoundedCanvas = sourceCanvas => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      const width = sourceCanvas.width;
      const height = sourceCanvas.height;

      canvas.width = width;
      canvas.height = height;
      context.imageSmoothingEnabled = true;
      context.drawImage(sourceCanvas, 0, 0, width, height);
      context.globalCompositeOperation = 'destination-in';
      context.beginPath();
      context.arc(
        width / 2,
        height / 2,
        Math.min(width, height) / 2,
        0,
        2 * Math.PI,
        true
      );
      context.fill();
      return canvas;
    };

    onMounted(() => {
      if (!cropper.value) return;
      cropper.value.replace(props.src);
    });

    const ready = () => {
      const containerData = cropper.value.getContainerData();
      const imageData = cropper.value.getImageData();
      cropper.value.setCanvasData({
        height: imageData.height
      });
      cropper.value.setCropBoxData({
        // NOTE: top の指定が効かないため、縦長の画像でroundの場合、センタリングにならない...
        left: containerData.width / 2 - imageData.height / 2,
        height: imageData.height
      });
      croppable.value = true;
    };

    const zoom = ratio => {
      cropper.value.relativeZoom(ratio);
    };

    const crop = () => {
      if (!croppable.value) return;
      // Crop
      const croppedCanvas = cropper.value.getCroppedCanvas();

      if (props.round) {
        // Round
        const roundedCanvas = getRoundedCanvas(croppedCanvas);
        emit('cropped', { imageData: roundedCanvas, file: file.value });
      } else {
        emit('cropped', { imageData: croppedCanvas, file: file.value });
      }
    };

    const onChangeFile = data => {
      file.value = data;
      cropper.value.replace(data.file);
    };

    return {
      imageSrc,
      cropper,
      croppable,
      ready,
      zoom,
      crop,
      onChangeFile
    };
  }
});
</script>
<style lang="scss">
.cropper-content {
  background-color: var(--background-color);
  max-height: 300px;
  max-width: 800px;

  &.loading {
    opacity: 0;
  }

  img {
    max-width: 100%;
  }

  .cropper-view-box {
    outline: 1px solid var(--primary-color);
    outline-color: var(--primary-light-color);
  }

  .cropper-point,
  .cropper-line {
    background-color: var(--primary-color);
  }

  &.round {
    .cropper-view-box,
    .cropper-face {
      border-radius: 50%;
    }
  }
}
</style>

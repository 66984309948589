<template>
  <BaseFileButton @change-file="file => $emit('change-file', file)">
    <div class="change-button-text">画像を変更</div>
  </BaseFileButton>
</template>
<script>
import { defineComponent } from 'vue';
import BaseFileButton from '@/components/ui/BaseFileButton.vue';

export default defineComponent({
  name: 'UploadTextButton',
  components: { BaseFileButton },
  emits: ['change-file']
});
</script>
<style lang="scss" scoped>
@import '@/styles/spacing';
@import '@/styles/effect';

.change-button-text {
  color: var(--primary-color);
  font-size: 10px;
  padding: $spacing-base;
  border-radius: 4px;
  @extend .hover-button-background;
}
</style>

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "side-content-menu" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = {
  key: 1,
  class: "side-content-menu-label"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Typography = _resolveComponent("Typography")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menus, (menu, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: `menu-${index}`,
        class: "side-content-menu-content"
      }, [
        _createVNode(_component_Typography, {
          color: "grey",
          size: "s",
          class: "mb-1"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(menu.label), 1)
          ]),
          _: 2
        }, 1024),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menu.items, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: `menu-item-${index}`,
            class: _normalizeClass(["side-content-menu-item", { current: (item.name || item.toName) === _ctx.routeName }])
          }, [
            (item.authority ? _ctx.acl.can(item.authority) : true)
              ? (_openBlock(), _createBlock(_component_Typography, {
                  key: 0,
                  "line-height": "1",
                  color: item.name === _ctx.routeName ? 'primary' : 'default'
                }, {
                  default: _withCtx(() => [
                    (item.toName !== _ctx.routeName)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          (item.onClick)
                            ? (_openBlock(), _createElementBlock("span", {
                                key: 0,
                                class: "side-content-menu-label",
                                onClick: item.onClick
                              }, [
                                _createVNode(_component_Icon, {
                                  color: item.toName === _ctx.routeName ? 'primary' : undefined,
                                  name: item.iconName,
                                  class: "mr-2"
                                }, null, 8, ["color", "name"]),
                                _createTextVNode(" " + _toDisplayString(item.label), 1)
                              ], 8, _hoisted_2))
                            : (_openBlock(), _createBlock(_component_router_link, {
                                key: 1,
                                to: { name: item.toName },
                                class: "side-content-menu-link"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_Icon, {
                                    color: item.toName === _ctx.routeName ? 'primary' : undefined,
                                    name: item.iconName,
                                    class: "mr-2"
                                  }, null, 8, ["color", "name"]),
                                  _createTextVNode(" " + _toDisplayString(item.label), 1)
                                ]),
                                _: 2
                              }, 1032, ["to"]))
                        ], 64))
                      : (_openBlock(), _createElementBlock("span", _hoisted_3, [
                          _createVNode(_component_Icon, {
                            color: item.toName === _ctx.routeName ? 'primary' : undefined,
                            name: item.iconName,
                            class: "mr-2"
                          }, null, 8, ["color", "name"]),
                          _createTextVNode(" " + _toDisplayString(item.label), 1)
                        ]))
                  ]),
                  _: 2
                }, 1032, ["color"]))
              : _createCommentVNode("", true)
          ], 2))
        }), 128))
      ]))
    }), 128))
  ]))
}
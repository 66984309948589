
import { defineComponent, computed } from 'vue';
import { useRoute } from 'vue-router';
import Icon from '@/components/ui/Icon.vue';
import Typography from '@/components/ui/Typography.vue';
import { useAcl } from '@/store/acl';

export default defineComponent({
  name: 'SideContentMenu',
  components: {
    Icon,
    Typography
  },
  props: {
    /**
     * {
     *  label?: string;
     *  items: {
     *    label: string;
     *    toName: string;
     *    iconName: string;
     *    onClick?: () => void | Promise<void>
     *  }[]
     * }[]
     */
    menus: Array
  },
  setup() {
    const route = useRoute();
    const acl = useAcl();

    return {
      routeName: computed(() =>
        route.matched[2] ? route.matched[1].name : route?.name
      ),
      acl
    };
  }
});
